import algoliasearch from "algoliasearch";

import { createClient } from "@supabase/supabase-js";

import { Database } from "../supabase/database";

export type OnboardedCustomer = {
  title: string;
  index: string;
};

export const Customers: Record<string, OnboardedCustomer> = {
  "fear-and": {
    title: "Fear&",
    index: "fear-and",
  },
  "tiny-meat-gang": {
    title: "Tiny Meat Gang",
    index: "tiny-meat-gang",
  },
  "the-official-podcast": {
    title: "The Official Podcast",
    index: "the-official-podcast",
  },
  "wine-about-it": { title: "Wine About It", index: "wine-about-it" },
  "bad-friends": { title: "Bad Friends", index: "bad-friends" },
  "chuckle-sandwich": { title: "Chuckle Sandwich", index: "chuckle-sandwich" },
  "the-basement-yard": {
    title: "The Basement Yard",
    index: "the-basement-yard",
  },
  "two-hot-takes": { title: "Two Hot Takes", index: "two-hot-takes" },
  "trash-taste": { title: "Trash Taste", index: "trash-taste" },
};

export const searchClient = algoliasearch(
  "FIUCYSGUH1",
  "56a3f5c448cb843ed1cb9b3d703f4ccd"
);

export const supabase = createClient<Database>(
  "https://xfyusfhycfyxggzlxflt.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhmeXVzZmh5Y2Z5eGdnemx4Zmx0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTY1MTQ5NzYsImV4cCI6MjAzMjA5MDk3Nn0.I9UGZcsJH6BtkecSiIc-WKLFycbW9Jv8ze3_GDmqUsY"
);

export type AlgoliaHit = {
  objectID: string;
  start: string;
  end: string;
  videoUrl: string;
  text: string;
  _highlightResult: {
    text: {
      matchedWords: string[];
      value: string;
    };
  };
};
